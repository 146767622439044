var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('v-card',[_c('v-card-title',[_vm._v(" Cargar Manifiestos(s) "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.fetchData}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":"","tile":"","elevation":"1"}},[_c('v-card-text',{staticStyle:{"padding-bottom":"0px"}},[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"clearable":"","label":"Buscar","hide-details":"","dense":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn-starkoms-primary",staticStyle:{"min-width":"138px"},attrs:{"dark":""}},[_vm._v("Buscar")])],1),_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"text-h6 font-weight-regular black--text"},[_c('i',{staticClass:"icon-filter_calendar"}),_vm._v(" Filtro por rango de fecha")]),_c('v-menu',{ref:"menu1",staticStyle:{"border-bottom":"1px solid #000"},attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"placeholder":"Todos","clearable":"","solo":"","readonly":""},on:{"click:clear":_vm.limpiarRango},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"id":"datepicker-starkoms","range":""},on:{"input":_vm.formatearRango},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1)])])],1)],1),_c('v-card-text',[_c('h3',{staticClass:"title"},[_vm._v("Seleccione el(los) pedido(s) que desea cargar manifiesto: "+_vm._s(_vm.selected.length ? (_vm.selected.length > 1 ? '('+_vm.selected.length+') Seleccionados' : '(1) Seleccionado'):''))]),_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.table,"show-select":"","search":_vm.search,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos...","sort-by":"id","sort-desc":true},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format('MMMM Do YYYY, h:mm:ss a'))+" ")])]}},{key:"item.fuente",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.source_name ? item.source_name : 'Sin asignar')+" ")])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"d-flex flex-column"},[_c('button',{class:'btn btn-sm btn-primary',domProps:{"textContent":_vm._s(item.current_state)}})])]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('br'),_c('br'),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selected.length),expression:"selected.length"}],staticClass:"float-left",attrs:{"small":"","color":"success","dark":""},on:{"click":_vm.openModalCargarManifiestos}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v("mdi-format-list-checks")]),_vm._v(" Cargar manifiesto"+_vm._s(_vm.selected.length > 1 ? 's':''))],1)],1)],1)],1)])]),_c('modal-cargar-manifiestos',{on:{"success":_vm.fetchData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }